<template>
  <div class="bba-banner-quitar-alt">
    <div class="bba-banner-quitar-alt__wrapper">
      <div class="bba-banner-quitar-alt__left">
        <div class="bba-banner-quitar-alt__title">Comparte tu lista sin publicidad</div>
        <!-- <div class="bba-banner-quitar-alt__subtitle">
          Tu familia y amig@s verán algo <br />
          de publicidad en tu lista
        </div> -->
      </div>
      <div class="bba-banner-quitar-alt__right">
        <div class="bba-banner-quitar-alt__amount">
          <strong>Sólo 10€</strong>
          <span>(Pago Único)</span>
        </div>
      </div>
    </div>
    <div class="bba-banner-quitar-alt__actions">
      <bbButton label="Quitar Publicidad" class="button--accent button--block" @click="upgradeToPremium()"></bbButton>
    </div>
  </div>
</template>

<script>
import bbButton from "../buttons/bbButton.vue";

export default {
  name: "BannerQuitarAdsVertical",
  components: {
    bbButton,
  },
  methods: {
    upgradeToPremium() {
      return this.$store.dispatch("upgradeToPremium");
    },
  },
};
</script>

<style lang="scss" scoped></style>
