<template>
  <div>
    <div class="p404 bg-cercles">
      <div class="p404__content">
        <img
          class="p404__favicon"
          src="../assets/img/logos/favicon.svg"
          alt=""
        />
        <h2 class="p404__title">{{ $t("pages.pageNotFound") }}</h2>
        <h4 class="p404__subtitle">
          {{ $t("pages.pageNotFoundSubtitle") }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  metaInfo() {
    return {
      title: this.$t("pages.pageNotFound") + " | HelloBB",
      meta: [
      { name: "description", content: this.$t("pages.pageNotFoundSubtitle") },
      { name: "robots", content: "noindex"},
      { name: 'forceShowPage', content: 'NotFoundPage' }
      ]
    };
  },
};
</script>
