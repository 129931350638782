export function $trackPinterest(event, email) {
    var a = document.createElement('script');
    var ps = '!function(e){if(!window.pintrk){window.pintrk = function () {' +
      'window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var ' +
        'n=window.pintrk;n.queue=[],n.version=\"3.0\";var ' +
        't=document.createElement("script");t.async=!0,t.src=e;var ' +
        'r=document.getElementsByTagName("script")[0];' +
        'r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");' +
      'pintrk("load", "2613200850153", {em: "' + email.toLowerCase() + '"});' +
      'pintrk("page");'
    a.text = ps;
    document.head.appendChild(a);

    var aa = document.createElement('script');
    var pss = 'pintrk("track", "' + event + '");'
    aa.text = pss;
    document.head.appendChild(aa);
}

export default {
    install(Vue) {
        Vue.prototype.$trackPinterest = $trackPinterest
    }
}

export {$trackPinterest as trackPinterest}
