<template>
  <article class="cart-item">
    <div class="cart-item__cover">
      <img :src="imageSrc" alt="" />
    </div>
    <div class="cart-item__content">
      <div class="cart-item__header">
        <div class="cart-item__title">{{ title.slice(0, 30) + ".." }}</div>
        <div v-if="showVariantTitle" class="cart-item__variation">
          {{ item.merchandise.title }}
        </div>
      </div>
      <div class="cart-item__actions">
        <div class="cart-item__spinner">
          <div class="cart-qty">
            <div
              class="cart-qty__action cart-qty__action--les cart-qty__action--button"
              @click="updateQuantity(item.quantity - 1)"
            >
              -
            </div>
            <div class="cart-qty__action cart-qty__action--selected">
              {{ item.quantity }}
            </div>
            <div
              class="cart-qty__action cart-qty__action--more cart-qty__action--button"
              @click="updateQuantity(item.quantity + 1)"
            >
              +
            </div>
          </div>
        </div>
        <div class="cart-item__price" v-if="!saleprice">
          <bdi>{{ $utils.formatMoney(price) }} </bdi>
        </div>

        <div
          class="cart-item__price"
          :class="saleprice ? 'cart-item__price--sale' : ''"
          v-else
        >
          <del>
            <bdi>PVR {{ $utils.formatMoney(price) }}</bdi>
          </del>
          <bdi>{{ $utils.formatMoney(saleprice) }}</bdi>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from "axios";

export default {
  name: "CartItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    showVariantTitle() {
      return this.item.merchandise.title !== 'Default Title' && this.item.merchandise.title !== '' && this.item.merchandise.title != null
    },
    imageSrc() {
      return this.item.merchandise?.image?.originalSrc;
    },
    price() {
      return Number(this.item.cost?.totalAmount?.amount).toFixed(2);
    },
    saleprice() {
      // TODO -> No tenim implementat?
      return false;
    },
    title() {
      return this.item.merchandise?.product?.title;
    },
  },
  methods: {
    async updateQuantity(quantity) {
      let response;
      this.loading = true;
      try {
        response = await axios.put("/cart", {
          cart: this.cart.id,
          quantity: quantity,
          line: this.item.id,
        });
      } catch (error) {
        this.loading = false;
        throw error;
      }

      this.loading = false;
      this.$store.commit("setCart", response.data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
