import countryData from "./countries.json";

// FIXME: Replace with `Object.groupBy` when using a Node.js version that
// supports it.
export const BY_CONTINENT = countryData.reduce((result, item) => ({
    ...result,

    [item.continent]: [
        ...(result[item.continent] || []),
        item,
    ],
}), {});

export const BY_COUNTRY_CODE = Object.fromEntries(
    Array.from(countryData).map(item => ([item.countryCode, item]))
);
