import { mapGetters } from "vuex";

import * as getters from "../store/getters";

const featureGetters = Object.fromEntries(
  Object.keys(getters)
    .filter(key => key.startsWith("feature") && key.endsWith("Available"))
    .map((key) => ([key, key]))
);

let logTimeout = null;

export default {
  data() {
    return {
      countryLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      ...featureGetters,

      countryCode: "countryCode",
      isServer: "isServer",
    }),
  },
  watch: {
    countryCode(newVal) {
      this.countryLoaded = !!newVal;
    }
  },
  async beforeMount() {
    if (!this.isServer) {
      // Required because features are based on geolocation.
      await this.$store.dispatch("prefetchCountry");
      this.countryLoaded = !!this.countryCode;
    }

    // Print features to console.
    //
    // Wrap in `setTimeout` to avoid spamming lots of messages.
    if (process.env.NODE_ENV === "development") {
      const features = Object.keys(featureGetters).reduce((result, key) => ({
        ...result,

        [key]: this[key],
      }), {});

      clearTimeout(logTimeout);

      logTimeout = setTimeout(() => {
        console.log(features);
      }, 1000);
    }
  },
}
