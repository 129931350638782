<template>
  <component
    class="button"
    :is="type"
    :href="href"
    :to="to"
    :class="[variant, icoStart ? 'button--ico' : '', icoEnd ? 'button--icoEnd' : '']"
    :disabled="disabled"
    @click="handleClick"
  >
    <!-- Loading button  -->
    <transition name="bbFade">
      <div class="button__loading" v-if="loading">
        <div class="bb-spinner bb-spinner--button">
          <div class="bb-spiner__content">
            <div class="bb-spinner__dots">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- Button  -->
    <i class="uil" v-if="icoStart" :class="icoStart"></i>
    {{ label }}
    <i class="uil" v-if="icoEnd" :class="icoEnd"></i>
  </component>
</template>

<script>
export default {
  name: "bbButton",
  props: {
    label: String,
    disabled: Boolean,
    icoStart: String,
    icoEnd: String,
    loading: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "button--primary",
    },
    href: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      if (this.href) {
        return "a";
      } else if (this.to) {
        return "router-link";
      } else {
        return "button";
      }
    },
  },
  methods: {
    handleClick(event) {
      event.stopPropagation();
      event.preventDefault();
      this.$emit("click");
    },
  },
};
</script>
