<template>
  <div class="app-store" v-if="!currentRouteIsConstructor || listActive">
    <a
      :href="$t('links.mobileAppUrlAndroid', lang)"
      class="app-store__item"
      target="_blank"
      rel="nofollow"
      @click="TrackConstructorClick('android')"
    >
      <img v-if="$i18n.locale === 'fr-FR' || iconsLang == 'fr-FR'" :loading="lazy" src="../../assets/img/logos/logo-app-google.fr-FR.svg" />
      <img v-else-if="$i18n.locale === 'it-IT' || iconsLang == 'it-IT'" :loading="lazy" src="../../assets/img/logos/logo-app-android-it.png" />
      <img v-else :loading="lazy" src="../../assets/img/logos/logo-app-google.svg" />
    </a>
    <a
      :href="$t('links.mobileAppUrlIos', lang)"
      class="app-store__item"
      target="_blank"
      rel="nofollow"
      @click="TrackConstructorClick('ios')"
    >
      <img v-if="$i18n.locale === 'fr-FR' || iconsLang == 'fr-FR'" :loading="lazy" src="../../assets/img/logos/logo-app-appstore.fr-FR.svg" />
      <img v-else-if="$i18n.locale === 'it-IT' || iconsLang == 'it-IT'" :loading="lazy" src="../../assets/img/logos/logo-app-apple-it.svg" />
      <img v-else :loading="lazy" src="../../assets/img/logos/logo-app-appstore.svg" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const CONSTRUCTOR_ROUTES = new Set([
  "list-constructor",
  "add-product",
  "constructor-product",
  "ConstructorBlogArticle",
]);

export default {
  name: "CtaAppLinks",
  props: {
    lazy: {
      type: String,
      default: "lazy"
    },
    iconsLang: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      "listActive": "listActive",
    }),
    currentRouteIsConstructor() {
      return CONSTRUCTOR_ROUTES.has(this.$route.name);
    },
    lang() {
      if (this.iconsLang) {
        return this.iconsLang;
      }
      else return this.$i18n.locale;
    }
  },
  methods: {
    TrackConstructorClick(value)
    {
      mixpanel.track("Click element constructor", {
        "Name": "App Links - " + value,
        "Section": "App Links",
        "Section number": "-",
        "Type": "Banner app",
        "Content": "System"
      });
    }
  }
};
</script>
<style></style>
