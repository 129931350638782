function removeDoubleSlashes(url) {
  return url.replace(
      /([^:]\/)\/+/g,
      "$1"
  );
}

/**
 * Ensure `referralUserId` is a valid string.
 *
 * If it's valid, returns `referralUserId` unchanged; otherwise, returns
 * `null`.
 */
function sanitizeReferralUserId(referralUserId) {
  if (typeof referralUserId !== "string") {
    return null;
  }
  if (referralUserId.trim() === "") {
    return null;
  }

  // TODO: Validate if `referralUserId` has the expected format.

  return referralUserId;
}

module.exports = {
  removeDoubleSlashes,
  sanitizeReferralUserId,
};
