export async function $parseUrl(url, options) {
    url = encodeURIComponent(url);

    var callto = "https://af-product-url-parser.azurewebsites.net/api/parseurl?url=" + url;
    options.body = JSON.stringify(options.body);
    const finalOptions = Object.assign({}, {
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.PRODUCT_URL_PARSER_API_KEY,
        },
    }, options)

    const response = await fetch(callto, finalOptions)
    if (response.ok) {
        try {
            const data = await response.json()
            return data
        } catch (err) { }
    } else if (response.status == 412) {
        return 'No se ha podido realizar la operación. Revise los datos.';
    } else if (response.status == 400) {
        const error = new Error('Nombre de usuario o contraseña incorrectos.')
        throw error
    } else if (response.status == 409) {
        var responseObject = {status: 409,msg:'Este email ya ha sido registrado. ¿Quieres iniciar sesión?'}
        return responseObject;
    } else {
        const error = new Error('No se ha podido realizar la operación. Revise los datos.')
        throw error
    }
}
export default {
    install(Vue, options) {
        Vue.prototype.$parseUrl = $parseUrl
    },
}

export {$parseUrl as parseUrl}
