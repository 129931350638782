export default {
    isEnabled: true, // Set this value based on whether Mixpanel is enabled
    //cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking

    identify(userId) {
        if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
            console.log('Mixpanel identify', userId);
            mixpanel.identify(userId);
        }
    },

    track(event, properties) {
        if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
            console.log('Mixpanel track', event, properties);
            mixpanel.track(event, properties);
        }
    },

    people: {
        set(properties) {
            if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
                mixpanel.people.set(properties);
            }
        },

        set_once(properties) {
            if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
                mixpanel.people.set_once(properties);
            }
        },

        increment(property, value) {
            if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
                mixpanel.people.increment(property, value);
            }
        }
    },

    register(properties) {
        if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
            mixpanel.register(properties);
        }
    }

    // Add other Mixpanel methods as needed

};
  