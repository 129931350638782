export default {
  // SSR mount and Before mount are not executed in SSR
  beforeMount() {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.$emit('close')
        if (typeof this.close === "function") {
          this.close()
        }
      }
    }
    document.addEventListener('keydown', escapeHandler)
    this.$once('hook:destroyed', () => {
      document.removeEventListener('keydown', escapeHandler)
    })
  }
}
