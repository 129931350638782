<template>
  <div class="bba-banner-quitar">
    <div class="bba-banner-quitar__left">
      <div class="bba-banner-quitar__title" v-html="titulo"></div>
    </div>

    <div class="bba-banner-quitar__amount">
      <strong>Sólo 10€</strong>
      <span>(Pago Único)</span>
    </div>
    <div class="bba-banner-quitar__actions">
      <bbButton label="Quitar Publicidad" class="button--accent button--sm" @click="upgradeToPremium()"></bbButton>
    </div>
  </div>
</template>

<script>
import bbButton from "../buttons/bbButton.vue";

export default {
  name: "BannerQuitarAds",
  components: {
    bbButton,
  },
  props: {
    titulo: {
      type: String,
      default: "Comparte tu lista <br /> sin publicidad",
    },
  },
  methods: {
    upgradeToPremium() {
      return this.$store.dispatch("upgradeToPremium");
    },
  },
};
</script>

<style lang="scss" scoped></style>
