<!-- ConfirmationMessage.vue -->
<template>
    <div>
        <div v-if="showMessage" class="confirmation">
        {{ message }}
            <span class="alert__close" @click="showMessage = false">
                <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z"
                    fill="#004085"
                />
                </svg>
            </span>
        </div>
        <div v-if="showMessageError" class="confirmation confirmation--error">
            {{ message }}
            <span class="alert__close" @click="showMessageError = false">
                <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z"
                    fill="#004085"
                />
                </svg>
            </span>
        </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        showMessage: false,
        showMessageError: false,
        message: "",
        fadeOut: false,
      };
    },
    computed: {
        ...mapGetters({
            confirmationMessage: 'confirmationMessage',
        }),
    },
    methods: {
      showConfirmationMessage(confirmationMessage) {
        this.message = confirmationMessage.message;
        this.showMessage = confirmationMessage.showMessage;
        this.showMessageError = confirmationMessage.showMessageError;
        //Auto-hide the message after a certain duration (e.g., 5 seconds)
        setTimeout(() => {
         this.hideConfirmationMessage();
        }, 5000);
      },
      hideConfirmationMessage() {
        this.fadeOut = true;
        setTimeout(() => {
          this.showMessage = false;
          this.showMessageError = false;
          this.message = "";
          this.fadeOut = false;
        }, 1000);
      },
    },
    watch: {
        confirmationMessage(newValue, oldValue) {
          console.log("watch ", newValue)
          if (newValue && newValue.message) {
              this.showConfirmationMessage(newValue);
          }
        },
    },
  };
  </script>
  