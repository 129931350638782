<template>
  <button
    class="button button--ico button--ico"
    :class="buttonVariant"
    @click="share"
    :disabled="!list.id"
  >
    <i class="uil uil-share-alt"></i> {{ $t("mylist.shareList") }}
    <modal-dialog
      :show="show"
      @close="close"
      target-class="modal__card--nopadding-all"
      :showAdsenseFooter="!loggedInUserIsPremium"
      :showContributionsFooter="!loggedInUserHasPiggyBankEnabled"
    >
      <div class="share-list-hucha">
        <header class="share-list-hucha__content share-list-hucha__content--bba">
          <div class="share-list-hucha__title">{{ $t("mylist.shareYourList") }}</div>

          <div class="share-list-hucha__description">
            {{ $t("mylist.shareListNoRegistration") }}
          </div>
          <div
            class="share-list-hucha__url"
            :class="copied ? 'share-list-hucha__url--active' : ''"
          >
            {{ guestListUrl }}
          </div>
          <div class="share-list-hucha__actions">
            <button
              class="button button--primary button--ico button--block"
              @click="copyLink()"
            >
              <template v-if="copied">
                <i class="uil uil-check"></i>
                {{ $t("mylist.copiedLink") }}
              </template>
              <template v-else>
                <i class="uil uil-copy"></i>
                {{ $t("mylist.copyLink") }}
              </template>
            </button>
            <a class="button button--light button--ico button--sm" @click="preview()">
              <i class="uil uil-eye"></i>
              {{ $t("mylist.watchPreview") }}
            </a>
          </div>
        </header>

        <!-- Banner «Quitar Publicidad» -->
        <main class="share-list-hucha__bba-actions" v-if="!loggedInUserIsPremium">
          <BannerQuitarAds />
        </main>
      </div>
    </modal-dialog>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import BannerQuitarAds from "../../../components/ads/BannerQuitarAds.vue";
import bbButton from "../../../components/buttons/bbButton.vue";
import ModalDialog from "../../../components/ui/ModalDialog";
import Adsense, { TYPE_IMAGE_SIDE } from "../../adsense/Adsense";

export default {
  name: "ShareListHucha",
  components: {
    ModalDialog,
    bbButton,
    BannerQuitarAds,
    Adsense,
  },
  props: {
    sharing: {
      type: Boolean,
      default: true,
    },
    buttonVariant: {
      type: String,
      default: "button--secondary",
    },
  },
  data() {
    return {
      show: false,
      copied: false,
      adType: TYPE_IMAGE_SIDE,
    };
  },
  computed: {
    ...mapGetters({
      list: "ownerList",
      user: "user",
      contributionsFlag: "contributionsFlag",
      loggedInUserIsPremium: "loggedInUserIsPremium",
      loggedInUserHasPiggyBankEnabled: "loggedInUserHasPiggyBankEnabled",
    }),
    guestListUrl() {
      return this.$utils.removeDoubleSlashes(
        process.env.APP_URL + "/guest/" + this.list.id
      );
    },
    listName() {
      return this.list.title != "" || this.list.title != null
        ? this.list.title
        : "Lista de " + this.list.ownerName;
    },
  },
  mounted() {
    console.log(this.user);
  },
  methods: {
    close() {
      this.copied = false;
      this.show = false;
    },
    redirect() {
      this.$router.push("/mi-hucha/crear");
    },
    preview() {
      this.close();
      this.copied = false;
      this.$router.push({
        name: "guest",
        params: { id: this.list.id },
        query: { vista: "invitado" },
      });
    },
    async copyLink() {
      try {
        await this.$copyText(this.guestListUrl);
      } catch (error) {
        console.log(error);
      }
      this.copied = true;
    },
    async share() {
      // Comentar o eliminar el siguiente bloque si no deseas intentar usar la interfaz de compartir nativa
      /*if (navigator.share) {
        try {
          await navigator.share({
            title: this.listName,
            url: this.guestListUrl,
          });
        } catch (error) {
          console.log(error);
        }
      } else {*/
      // Este bloque siempre se ejecutará, mostrando el modal personalizado
      if (this.sharing) {
        this.show = true;
      } else {
        this.$emit("share");
      }
      /*}*/

      // Segmento de Mixpanel, puedes dejarlo tal cual si deseas seguir rastreando el evento de compartir.
      mixpanel.track("Compartir llista", {
        "Share Platform": "Desktop",
        "Share client": "Webapp",
      });
      mixpanel.people.set({ "Last Share ": new Date().toISOString() });
      mixpanel.people.set_once({
        "First Share": new Date().toISOString(),
      });
      mixpanel.people.increment("Total share");
    },
    redirectToSection() {
      this.$router.push("/mi-hucha/crear").then(() => {
        this.$nextTick(() => {
          const section = document.getElementById("comoFunciona");
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        });
      });
    },
  },
};
</script>
