const time = 6000
let timeout

function clearTimeOut() {
    if (timeout) {
        window.clearTimeout(timeout)
        timeout = null
    }
}

function close(store) {
    store.commit('setShowAlert', false)
    store.commit('setAlertType', '')
    store.commit('setAlertMessage', '')
    clearTimeOut()
}

function alert(store) {
    const alert = {
        success(message, key = null) {
            store.commit('setAlertType', 'success');
            store.commit('setAlertMessage', message);
            store.commit('setShowAlert', true);
            if (key) {
                store.commit('setShowAlertKey', key);
            }
            timeout = window.setTimeout(() => close(store), time)
        },
        error(message, retry = false, key = null) {
            store.commit('setAlertType', 'error');
            store.commit('setAlertMessage', message);
            store.commit('setShowAlert', true);

            if (retry) {
                store.commit('setShowRetry', true);
            }
            if (key) {
                store.commit('setShowAlertKey', key);
            }
            timeout = window.setTimeout(() => close(store), time)
        },
        clearTimeOut
    }
    return alert
}
export default {
    install(Vue, store) {
        Vue.prototype.$alert = alert(store)
    }
}