import axios from "axios";

export default {
  methods: {
    async logout() {
      let response = await axios.post("/logout");
      if (parseInt(response.status) === 200) {
        this.$store.commit("logout", "");
        this.$store.commit("setOwnerList", {
          funds: [],
          products: [],
        });

        this.$store.commit("setCart", null);

        // TODO
        this.$store.commit("setMyList", {
          funds: [],
          products: [],
        });

        this.$store.commit("setListActive", false);

        await this.$router.replace({ name: "home" });
      } else {
        const errorMessage = "Incorrect logout!";
        console.log(errorMessage);
        throw new Error(errorMessage);
      }
    },
  },
};
