<template>
  <section class="header-cart">
    <div class="header-cart__ico" @click="openCart()">
      <img class="header-nav-icon" src="../../assets/img/ico/ico-cart.svg" alt="" />
      <span
        class="header-cart__count"
        :class="length < 1 ? 'header-cart__count--empty' : ''"
      >
        {{ length }}
      </span>
    </div>
    <transition name="bbUpMin">
      <aside
        class="header-cart__dropdown"
        v-if="showCart"
        v-click-outside="config"
        :class="length < 1 ? 'header-cart__dropdown--empty' : ''"
      >
        <header class="header-cart__header">
          <div class="header-cart__title">Carrito</div>
          <div class="header-cart__close" @click="closeCart()">
            <img src="../../assets/img/ico/ico-close.svg" alt="Close" />
          </div>
        </header>
        <main class="header-cart__main">
          <template v-if="length > 0">
            <CartItem v-for="item in items" :key="item.id" :item="item" :cart="cart" />
          </template>
          <template v-else>
            <div class="header-cart__empty">
              <img src="../../assets/img/logos/logo-hellobb-isotip.svg" alt="" />
              <span>Tu carrito está vacío.</span>
            </div>
          </template>
        </main>
        <footer class="header-cart__footer" v-if="length > 0">
          <div class="header-cart__total">
            <div class="header-cart__total-label">Total</div>
            <div class="header-cart__total-price">
              {{ $utils.formatMoney(total) }}</span>
            </div>
          </div>
          <div class="header-cart__actions">
            <div class="header-cart__button">
              <div id="checkout-button" class="button button--block button--primary" @click="checkout()">
                Tramitar pedido
              </div>
            </div>
          </div>
          <div class="header-cart__info">
            Los gastos de envío se especifican en la pantalla de pago. Transporte gratis
            en pedidos de +50€. <span>Recíbelo en 24/48h.</span>
          </div>
        </footer>
      </aside>
    </transition>
  </section>
</template>

<script>
import CartItem from "./CartItem.vue";
import vClickOutside from "v-click-outside";
import { mapGetters } from "vuex";
import axios from "axios";

function is_server() {
  return !(typeof window != "undefined" && window.document);
}

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Cart",
  components: {
    CartItem,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      config: {
        handler: this.closeCart,
        events: ["dblclick", "click"],
      },
    };
  },
  computed: {
    ...mapGetters({
      cart: "cart",
      toggle: "toggleCart",
      myList: "myList",
      isAuthenticated: "isAuthenticated",
    }),
    length() {
      return (
        this.items?.reduce(
          (accumulator, currentValue) => accumulator + currentValue.quantity,
          0
        ) || 0
      );
    },
    items() {
      return this.cart?.lines?.edges?.map((edge) => edge.node);
    },
    showCart() {
      return this.toggle;
    },
    total() {
      return (
        this.items
          ?.reduce(
            (accumulator, currentValue) =>
              accumulator + Number(currentValue.cost?.totalAmount?.amount),
            0
          )
          .toFixed(2) || 0
      );
    },
  },
  watch: {
    toggle(newValue) {
      newValue ? this.disableBodyScroll() : this.enableBodyScroll()
    },
  },
  methods: {
    async openCart() {
      let cart = null;
      if (this.cart) {
        const cartId = this.cart.id?.replace("gid://shopify/Cart/", "");
        if (cartId) cart = await axios.get("/cart/" + cartId);
      }
      if (cart && cart.data) this.$store.commit('setCart', cart.data)
      this.$store.commit("setToggleCart", true);
    },
    closeCart() {
      this.$store.commit("setToggleCart", false);
    },
    disableBodyScroll() {
      if (!is_server()) {
        document.body.classList.add("cart-open");
      }
    },
    enableBodyScroll() {
      if (!is_server()) {
        document.body.classList.remove("cart-open");
      }
    },
    checkout() {
      if (this.items?.length > 0) {
        if (this.cart) {
          this.trackTramitarPedido();
          if (this.myList?.id) {
            this.sendCheckout();
          }
          window.open(this.cart.checkoutUrl, "_self");
        }
      }
    },
    async sendCheckout() {
      let arr = this.cart.checkoutUrl.split("/");
      let chid = arr[arr.length - 1];
      await fetch(
        process.env.API + "/list/" + this.myList.id + "/draftcheckout/" + chid,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    async trackTramitarPedido() {
      const role = this.isAuthenticated ? "Propietari" : "Regalador";
      window.mixpanel.track("Tramitar comanda", {
        "Total amount": this.total,
        "Total items": this.length,
        "Cart ID": this.cart.id,
        "Role": role,
      });
    },
  },
};
</script>
