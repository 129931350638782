import { createApp } from './app';
import './assets/scss/main.scss';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import './assets/css/tailwind_prod.css';
import Cookies from 'js-cookie'

async function clientEntry() {
    const cookies = Cookies.get();

    const { app, router, store } = await createApp({ state: window.__INITIAL_STATE__ , cookies: cookies});

    if (window.__INITIAL_STATE__) {
      // We initialize the store state with the data injected from the server
      store.replaceState(window.__INITIAL_STATE__)
    }

    router.onReady(() => {
      router.beforeResolve((to, from, next) => {
        // If this isn't an initial page load.
        if (to.name) {
          if (to.params?.progress !== false) {
            // Start the route progress bar.
            NProgress.start()
          }
        }
        next()
      })

      router.afterEach((to) => {
        // Complete the animation of the route progress bar.
        if (to.params?.progress === false) return
        NProgress.done()
      })

      app.$mount('#app');
    });
}

// The code was wrapped in an `async` function just to be able to use `await`,
// but we still need to call it.
clientEntry();
