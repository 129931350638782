<template>
    <div>
      <!-- <button id="hideChat" @click.stop="showMyLandbot = !showMyLandbot">HOLA</button> -->
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'Landbot',
    data() {
      return {
        myLandbot: null,
      };
    },
    computed: {
        ...mapGetters({
            landbotVisible: 'landbotVisible',
            reloadLandbot: 'reloadLandbot'
        }),
    },
    mounted() {
      const currentPath = window.location.pathname;
      const isAuthenticated = window.__INITIAL_IS_AUTHENTICATED__ === true;
      if(currentPath.startsWith('/landing/')) {
        return;
      }
      let landbotConfig = {
        configUrl: '',
      };
  
      if (!isAuthenticated) {
        if (currentPath.startsWith('/guest/')) {
          landbotConfig.configUrl = 'https://storage.googleapis.com/landbot.online/v3/H-2090785-DVQ6W9JD7O5RNMZS/index.json';
        } else {
          landbotConfig.configUrl = 'https://storage.googleapis.com/landbot.online/v3/H-2094277-LYE2E1WDOEF8QSKW/index.json';
        }
      } else {
        landbotConfig.configUrl = 'https://storage.googleapis.com/landbot.online/v3/H-2090785-DVQ6W9JD7O5RNMZS/index.json';
      }
  
      this.loadLandbotScript().then(() => {
        this.myLandbot = new Landbot.Livechat(landbotConfig);
      });
    },
    methods: {
      loadLandbotScript() {
        return new Promise((resolve, reject) => {
          var s = document.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.addEventListener('load', resolve);
          s.addEventListener('error', reject);  // Handle script loading error
          s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
          var x = document.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        });
      },
      hideChat() {
        //hide element with class LandbotLivechat
        let d = document.getElementsByClassName('LandbotLivechat')[0]
        if (d) {
          d.style.display = 'none';
        }
      },
      showChat() {
        let d = document.getElementsByClassName('LandbotLivechat')[0]
        if (d) {
          d.style.display = 'block';
        }
      },
    },
    watch: {
        landbotVisible: {
            handler: function (val) {
                if (val) {
                    this.showChat();
                }
                else {
                    this.hideChat();
                }
            },
        },
        reloadLandbot: {
          handler: function (val) {
            // Lógica para recargar Landbot aquí
            let landbotConfig = {
              configUrl: '',
            };
            landbotConfig.configUrl = 'https://storage.googleapis.com/landbot.online/v3/H-2090785-DVQ6W9JD7O5RNMZS/index.json';
            this.loadLandbotScript().then(() => {
              this.myLandbot = new Landbot.Livechat(landbotConfig);
            });
          },
        },   
      },
  };
  </script>
  