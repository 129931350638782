<template>
  <div>
    <div class="p404 bg-cercles">
      <div class="p404__content">
        <!-- <img
          class="p404__favicon"
          src="../assets/img/logos/favicon.svg"
          alt=""
        /> -->
        <h2 class="p404__title">
          {{ $t("pages.featureNotAvailableInt") }}
        </h2>
        <h4 class="p404__subtitle">
          {{ $t("pages.featureNotAvailableIntSubtitle") }}
          <a class="link" href="mailto:hola@hellobb.net">{{ $t("generic.here") }}</a> {{ $t("pages.featureNotAvailableIntSubtitle") }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotAvailableInternationalPage",
  metaInfo() {
    return {
      title:  this.$t("pages.featureNotAvailableInt") + " | HelloBB",
      meta: [
        { name: "description", content: this.$t("pages.featureNotAvailableIntMeta") },
        { name: "robots", content: "noindex"},
        { name: 'forceShowPage', content: 'NotAvailableInternationalPage' }
      ]
    };
  },
};
</script>
