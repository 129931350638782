<template>
  <header class="header header--list">
    <div class="header__container header__container--onboarding">
      <div class="header__logo">
        <img src="../assets/img/logos/logo-hellobb-dark.svg" alt="Logo HelloBB" />
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "HeaderOnboardingGuest",
  computed: {
    ...mapGetters({
      list: "myList",
    }),
  },
  methods: {
    link() {
      this.$router.push({ name: "guest", params: { id: this.list.id } });
    },
  },
};
</script>
<style></style>
