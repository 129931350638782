<template>
  <ins class="adsbygoogle"
    style="display:block"
    data-ad-client="ca-pub-7098941501180575"
    data-ad-format="fluid"
    data-full-width-responsive="true"
    :data-ad-slot="2856092344">
  </ins>
</template>

<script>
export const TYPE_NONE = "none";
export const TYPE_IMAGE_SIDE = "image_side";
export const TYPE_IMAGE_ABOVE = "image_above";

const AD_LAYOUT_KEYS = {
  [TYPE_IMAGE_SIDE]: "-fn-1s-1x+1p+co",
  [TYPE_IMAGE_ABOVE]: "-73+ed+2i-1n-4w",
};

const AD_SLOTS = {
  [TYPE_IMAGE_SIDE]: "5912612477",
  [TYPE_IMAGE_ABOVE]: "2331250255",
};

export default {
  name: 'Adsense',
  data() {
    return {
      TYPE_NONE,
      TYPE_IMAGE_SIDE,
      TYPE_IMAGE_ABOVE,
    };
  },
  props: {
    adType: {
      type: String,
      default: TYPE_IMAGE_SIDE,
    },
  },
  methods: {
    getAdLayoutKey(adType) {
      return AD_LAYOUT_KEYS[adType] || "";
    },
    getAdSlot(adType) {
      return AD_SLOTS[adType] || "";
    },
  },
  async mounted () {
    // if(this.$utils.isApp()) {
    //   if (this.$utils.appVersion() === null) return
    // }

    if(this.$utils.isApp()) return;


    //console.log('mounted')
    //console.log(window.adsbygoogle)

    //if isApp and we are in iOS platform, we don't want to show ads

    // console.log("user agent: ", window.navigator.userAgent)
    // if(this.$utils.isApp()) {
    //   const userAgent = window.navigator.userAgent;
    //   let a = /iPad|iPhone|iPod/.test(userAgent);
    //   if (a) {
    //     return;
    //   }
    // }

    try {
      // This could cause an exception:
      //
      //     TagError: adsbygoogle.push() error: All 'ins' elements in the DOM with class=adsbygoogle already have ads in them.
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err) {
      console.error(err);
    }
  }
}
</script>
