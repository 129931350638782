export function $formatUrlhelloBB(s) {
    if(!s) return ''
    var r = s.toLowerCase();
    //delete spaces at the beginning and the end of the string
    r = r.replace(/^\s+|\s+$/g, "");
    var non_asciis = {
        a: "[àáâãäå]",
        ae: "æ",
        c: "ç",
        e: "[èéêë]",
        i: "[ìíîï]",
        n: "ñ",
        o: "[òóôõö]",
        oe: "œ",
        u: "[ùúûűü]",
        y: "[ýÿ]",
        x100: "[%]",
    };
    for (var i in non_asciis) {
        r = r.replace(new RegExp(non_asciis[i], "g"), i);
    }
    r = r.replace(/\./g, "-");
    r = r.replace(/[^A-Za-z0-9]/g, '-');
    r = r.replace(/[-]+/g, "-");
    return r.replace(/\s/g, "-");
}

export function $cutText (input, maxLength, finalString)
{
    if(input == null) return input;
    if(input.length > maxLength)
    {
        return input.slice(0, maxLength - finalString.length) + finalString;
    }
    return input;
}

export default {
    install(Vue) {
        Vue.prototype.$formatUrlhelloBB = $formatUrlhelloBB,
        Vue.prototype.$cutText = $cutText
    }
}

export {$formatUrlhelloBB as formatUrlhelloBB}
