<template>
  <span
    class="link link--bold link--underline"
    v-if="!loggedInUserIsPremium"
    @click="upgradeToPremium()"
  >
    Quitar Publicidad
    <modal-dialog
      :show="showModal && !hiddenByUser"
      @close="hiddenByUser = true"
      target-class="modal__card--nopadding-all"
      :showAdsenseFooter="!loggedInUserIsPremium"
    >
      <div class="share-list-hucha">
        <header class="share-list-hucha__content share-list-hucha__content--bba">
          <div class="share-list-hucha__title">Comparte tu lista sin publicidad</div>
        </header>

        <!-- Banner «Quitar Publicidad» -->
        <main class="share-list-hucha__bba-actions">
          <!-- <BannerQuitarAdsVertical /> -->
          <BannerQuitarAds></BannerQuitarAds>
        </main>
      </div>
    </modal-dialog>
  </span>
</template>

<script>
import { mapGetters } from "vuex";

import bbButton from "../../../components/buttons/bbButton.vue";
import ModalDialog from "../../../components/ui/ModalDialog";
import BannerQuitarAdsVertical from "../../../components/ads/BannerQuitarAdsVertical.vue";
import BannerQuitarAds from "../../../components/ads/BannerQuitarAds.vue";
import Adsense, { TYPE_IMAGE_SIDE } from "../../adsense/Adsense";

export default {
  name: "ModalQuitarPublicidad",
  components: {
    ModalDialog,
    bbButton,
    BannerQuitarAdsVertical,
    BannerQuitarAds,
    Adsense,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      loggedInUserIsPremium: "loggedInUserIsPremium",
    }),
  },
  data() {
    return {
      adType: TYPE_IMAGE_SIDE,

      // Vue complains if we mutate a prop from within the same component, so
      // that's why we modify this variable and avoid modifying `showModal`.
      hiddenByUser: false,
    };
  },
  methods: {
    upgradeToPremium() {
      return this.$store.dispatch("upgradeToPremium");
    },
  },
};
</script>
