<template>
  <div class="h-sticky h-sticky--blog">
    <header-partial/>
  </div>
</template>

<script>
import HeaderPartial from "./components/HeaderPartial";

export default {
  name: "HeaderBlog",
  components: {
    HeaderPartial
  }
}
</script>