import vClickOutside from "v-click-outside"

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      config: {
        handler: this.close,
        events: ['dblclick', 'click']
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
