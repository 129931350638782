<template>
  <section class="alert-hucha">
    <div class="alert-hucha__content">
      <div class="alert-hucha__ico">
        <img src="../../../assets/img/ico/ico-color-hucha-pig.svg" alt="" />
      </div>
      <div class="alert-hucha__label">
        <slot> </slot>
      </div>
      <div class="alert-hucha__actions">
        <a class="link link--bold link--underline color-dark" @click="goToHucha()"
          >{{ $t("mylist.enablePiggybankButton") }}</a
        >
      </div>
    </div>
    <div class="alert-hucha__close">
      <i class="uil uil-times" @click="close()"></i>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "AlertHucha",
  computed: {
    ...mapGetters({
      isApp: "isApp",
    }),
  },
  methods: {
    close() {
      //save in localstorage a flag that should expire in one week
      const now = new Date();
      const expires = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
      const item = {
        value: "false",
        expiry: expires.getTime(),
      };
      localStorage.setItem("alertHucha", JSON.stringify(item));
      
      //close the alert
      this.$emit("closeAlertHucha");
    },
    goToHucha() {
      if(!this.isApp)this.$router.push("/mi-hucha/crear");
      else location.href = process.env.APP_URL + "mi-hucha/crear?source=app";
    },
  },
};
</script>

<style lang="scss" scoped></style>
